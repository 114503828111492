import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  ariaControls?: string;
  id?: string;
  isAriaHidden: boolean;
  isNavExpanded: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

export default function Toggle({
  ariaControls,
  id,
  isAriaHidden,
  isNavExpanded,
  onClick,
}: Props) {
  return (
    <button
      className="site-header__nav__toggle"
      aria-controls={ariaControls || "main-navigation"}
      aria-expanded={isNavExpanded}
      aria-haspopup="menu"
      aria-hidden={isAriaHidden}
      aria-label={(isNavExpanded ? "Hide" : "Show") + " navigation menu"}
      id={id || "main-navigation-toggle"}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={isNavExpanded ? faXmark : faBars} />
    </button>
  );
}
