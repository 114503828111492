import clsx from "clsx";
import Link from "next/link";

type Props = {
  href: string;
  label: string;
  variant?: "button";
};

export default function MenuItem({ href, label, variant }: Props) {
  return (
    <Link
      href={href}
      className={clsx("site-header__nav__menu__link", {
        "site-header__nav__menu__link--is-button": variant === "button",
      })}
      role="menuitem"
    >
      {label}
    </Link>
  );
}
