import clsx from "clsx";
import { Children } from "react";

type Props = {
  ariaLabel?: string;
  children?: React.ReactNode[];
  depth?: number;
  id?: string;
  isAriaHidden?: boolean;
  isNavExpanded?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

export default function Menu({
  ariaLabel,
  children,
  depth,
  id,
  isAriaHidden,
  isNavExpanded,
  onClick,
}: Props) {
  return (
    <ul
      className={clsx("site-header__nav__menu ", {
        "site-header__nav__menu--has-depth-0": depth === 0,
        "site-header__nav__menu--has-depth-1": depth === 1,
        "site-header__nav__menu--is-expanded": isNavExpanded === true,
        "site-header__nav__menu--is-collapsed": isNavExpanded === false,
      })}
      id={id || "main-navigation"}
      aria-hidden={isAriaHidden}
      aria-label={ariaLabel || "Main navigation menu"}
      role="menu"
    >
      {children &&
        Children.map(children, (child) => (
          <li
            className="site-header__nav__menu__item"
            role="presentation"
            onClick={onClick}
          >
            {child}
          </li>
        ))}
    </ul>
  );
}
