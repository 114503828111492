type Props = {
  ariaLabel?: string;
  children?: React.ReactNode;
};

export default function Nav({ ariaLabel, children }: Props) {
  return (
    <nav
      className="site-header__nav"
      aria-label={ariaLabel || "Main Navigation"}
    >
      {children}
    </nav>
  );
}
