"use client";

import { useEffect, useState } from "react";
import { createPortal } from "react-dom";

import { APP_NAME } from "@shared/constants";

type Disclaimers = {
  [key: string]: string;
};

export default function DomainWideDisclaimer() {
  const [target, setTarget] = useState<HTMLElement | null>(null);

  const disclaimers: Disclaimers = {
    "veteran-debt-assistance":
      "Veteran Debt Assistance and the programs/services offered through its providers are not affiliated with, approved, sponsored, or endorsed by any state or federal government agency.",
  };

  // wait for page to load before querying target element
  useEffect(() => {
    let siteFooter = document.querySelector(
      "#site-footer .site-footer__container",
    ) as HTMLElement | null;

    if (siteFooter) {
      setTarget(siteFooter);
    }
  }, []);

  return (
    target &&
    createPortal(
      <div className="site-footer__disclaimer">
        <p>{disclaimers[APP_NAME]}</p>
      </div>,
      target as HTMLElement,
    )
  );
}
